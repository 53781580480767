import { useStaticQuery, graphql } from "gatsby"

function getItemUrlFromPages(itemUid, pages) {
  const featuredItemCategoryPage = pages.find(page => page.context.category.items.find(i => i.uid === itemUid))
  return !!featuredItemCategoryPage ? `${featuredItemCategoryPage.path}${itemUid}/` : ''
}

function getFeaturedItemTitle(featuredItem) {
  return featuredItem.title && featuredItem.title.length ?
    featuredItem.title.map(_ => _.text).join(" ") :
    featuredItem.title.text
}

// ? Gatsby Docs: https://www.gatsbyjs.cn/docs/use-static-query/
export const useSitePages = () => {
  const { collections, shop, featured } = useStaticQuery(
    graphql`
      query SitePages {
        collections: allSitePage(filter: {context: {collection: {uid: {ne: null}}}}, sort: {fields: context___collection___first_publication_date, order: DESC}) {
          nodes {
            path
            context {
              collection {
                description
                title
              }
            }
          }
        }
        shop: allSitePage(filter: {context: {category: {name: {ne: null}}}}) {
          nodes {
            path
            context {
              category {
                name
                items {
                  uid
                }
              }
            }
          }
        }
        featured: prismicFeaturedInShop {
          dataRaw {
            description
            title {
              text
            }
            featured_item {
              slug
              type
              uid
            }
          }
        }
      }
    `
  )

  const collectionPages = {
    title: "Collections",
    items: collections.nodes.map(sitePage => ({
      title: sitePage.context.collection?.title,
      location: sitePage.path,
      description: sitePage.context.collection?.description,
    }))
  }
  const shopPages = {
    title: "Shop",
    items: shop.nodes.map(sitePage => ({
      title: sitePage.context.category.name,
      location: sitePage.path,
    }))
  }

  // ? Add additional entry for all product pages
  collectionPages.items.push({ title: "All collections", location: "/collection/all/" })
  // shopPages.items.push({ title: "Shop all collections", location: "/shop/all/" })

  // * Prepend link of feature item|collection on the top of the shop nav list
  if (featured && featured.dataRaw) {
    const { featured_item } = featured.dataRaw
    shopPages.items.unshift({
      title: getFeaturedItemTitle(featured.dataRaw),
      description: featured.dataRaw.description,
      location: featured_item.type === 'collection'
        ? `/collection/${featured_item.uid}/`
        : getItemUrlFromPages(featured_item.uid, shop.nodes),
    })
  }

  return {
    collectionPages,
    shopPages
  }
}
